.card-list {
  margin: 24px 0 10px; }

.card {
  margin: 0 auto 30px;
  text-align: center;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 4px 10px 4px #f5f1fa;
  background-color: #ffffff; }

.card__pic {
  display: block;
  height: 180px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin: 0px auto;
  background: rgba(140, 142, 145, 0.5); }

.card__name {
  margin: 15px 0 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #c00000; }

.card__text {
  border-top: 1px solid #f5f1fa;
  margin: 10px 17px 0;
  padding: 13px 0 11px;
  color: #000; }
  .card__text p {
    margin-bottom: 0; }
