.header {
  padding: 10px 0;
  background-color: #000000;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff; }
  .header a {
    color: currentColor; }
  .header .icon {
    fill: currentColor; }
  .header .icon-menu {
    font-size: 16px;
    margin-right: 10px; }
  .header .icon-info {
    font-size: 20px;
    margin-left: 13px; }
  .header .icon-deleted {
    margin-left: 6px;
    font-size: 20px; }
  .header .icon-arrowback {
    font-size: 21px;
    margin-right: 16px; }
  @media only screen and (min-width: 580px) {
    .header {
      padding: 16px 0;
      font-size: 16px;
      font-weight: 400; }
      .header .icon-deleted,
      .header .icon-info,
      .header .icon-menu,
      .header .icon-pdf,
      .header .icon-x {
        font-size: 26px; } }

.header__info {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .header__info:hover {
    cursor: pointer; }
  .header__info > span {
    display: flex;
    align-items: center;
    justify-content: center; }

.header__actions {
  display: flex;
  justify-content: flex-end; }
  .header__actions .icon {
    font-size: 25px;
    margin-left: 8px; }
