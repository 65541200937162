.icon-arrowback,
.icon-arrownext-bt {
  width: 0.59em;
  height: 1em;
  fill: #fff; }

.icon-arrownext {
  width: 0.57em;
  height: 1em;
  fill: #c00000; }

.icon-change {
  width: 1.14em;
  height: 1em;
  fill: #c00000; }

.icon-checked {
  width: 1em;
  height: 1em;
  fill: none; }

.icon-close {
  width: 1em;
  height: 1em;
  fill: initial; }

.icon-edit {
  width: 1em;
  height: 1em;
  fill: initial; }

.icon-eye-not {
  width: 1.18em;
  height: 1em;
  fill: #b9b9b9; }

.icon-eye {
  width: 1.68em;
  height: 1em;
  fill: none; }

.icon-info {
  width: 1em;
  height: 1em;
  fill: none; }

.icon-pdf {
  width: 0.8em;
  height: 1em;
  fill: #fff; }

.icon-x {
  width: 0.8em;
  height: 1em;
  fill: #fff; }

.icon-unkill {
  width: 1em;
  height: 1em;
  fill: none; }

.icon-deleted,
.icon-deleted-white {
  width: 1em;
  height: 1em; }

.icon-unkill {
  width: 1em;
  height: 1em;
  fill: none; }

.icon-menu {
  width: 1em;
  height: 1em;
  fill: #fff; }

.icon-magic-wand {
  width: 1em;
  height: 1em;
  fill: #4e4e4e; }

body {
  font-family: "Montserrat", sans-serif;
  color: #000; }

.container {
  width: 100%;
  max-width: 480px;
  padding: 0 16px;
  margin: 0 auto; }
  @media only screen and (min-width: 580px) {
    .container {
      max-width: 640px; } }

.linksContainer {
  margin: 0; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.link-red {
  font-size: 14px;
  line-height: 1.57;
  color: #c00000 !important;
  text-decoration: underline; }
  .link-red:focus, .link-red:hover {
    color: #da0000; }

.separator {
  height: 10px;
  display: inline-block;
  width: 1px;
  margin: 0 9px;
  background-color: #4a4a4a; }

.btn-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none; }

.btn-red {
  color: #c00000; }
  .btn-red span + .icon {
    margin-left: 16px; }
  .btn-red .icon-checked {
    font-size: 21px; }

.btn-white {
  color: #FFFFFF; }
  .btn-white span + .anticon,
  .btn-white span + .icon {
    margin-left: 16px; }
  .btn-white .icon-checked {
    font-size: 21px; }

.btn-green {
  color: #25b107; }
  .btn-green span + .anticon,
  .btn-green span + .icon {
    margin-left: 16px; }
  .btn-green .icon-checked {
    font-size: 21px; }

.buttonicon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  width: 200px;
  height: 48px;
  background-color: #c00000;
  border: none;
  transition: background-color .3s ease; }
  .buttonicon.large {
    width: 240px; }
  .buttonicon.green {
    background-color: #25b107; }
    .buttonicon.green:hover {
      background-color: #2aca08; }
  .buttonicon.black {
    background-color: #000000; }
    .buttonicon.black:hover {
      background-color: #333333; }
  .buttonicon:hover {
    background-color: #da0000; }
  .buttonicon .icon {
    font-size: 20px;
    margin: 0 7px;
    fill: #fff; }

.imagesingle__actions .exclude-buttons-block {
  flex-direction: row; }

.imagesingle__actions .exclude-button {
  margin-right: 15px; }

@media only screen and (max-width: 500px) {
  .imagesingle__actions .exclude-buttons-block {
    flex-direction: column; }
  .imagesingle__actions .exclude-button {
    margin-right: 0px; } }

.btn__mark-done {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 35px; }

.icon-checked {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 1px; }

.text-bold {
  font-weight: 700; }

.text-semibold {
  font-weight: 600; }

.text-small {
  font-size: 12px; }

.c-black {
  color: #000; }

.flex-center {
  display: flex;
  align-items: center; }

.flex-center-stretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  line-height: 2em; }
  .flex-center-stretch > div,
  .flex-center-stretch > a {
    display: flex;
    justify-content: center;
    align-items: center; }

.justify-content-end {
  justify-content: flex-end; }

.ant-form-item {
  font-family: "Montserrat", sans-serif; }

.ant-form-item-required:before {
  display: none; }

.alertify-logs.bottom,
.alertify-logs:not(.top) {
  top: 46px;
  height: 52px; }

.infinite-scroll-container > div {
  width: 100%; }

.ant-btn {
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none; }
  .ant-btn.regular-button {
    font-weight: normal; }
  .ant-btn.custom-link-button {
    color: rgba(0, 0, 0, 0.65);
    font-size: 25px; }
    .ant-btn.custom-link-button:hover {
      opacity: 0.7; }

a.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.ant-btn-red {
  background-color: #c00000;
  border: none;
  color: #fff !important; }
  .ant-btn-red:focus, .ant-btn-red:hover {
    color: #fff;
    background-color: #da0000; }

.ant-tabs--styled {
  font-family: inherit; }
  .ant-tabs--styled .ant-tabs-bar {
    border-bottom: none;
    background-color: #000;
    color: #fff;
    font-size: 20px; }
  .ant-tabs--styled .ant-tabs-nav-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    padding: 0 16px; }
    @media only screen and (min-width: 580px) {
      .ant-tabs--styled .ant-tabs-nav-container {
        max-width: 640px; } }
  .ant-tabs--styled .ant-tabs-nav {
    width: 100%; }
    .ant-tabs--styled .ant-tabs-nav > div {
      display: flex;
      justify-content: space-between; }
  .ant-tabs--styled .ant-tabs-nav .ant-tabs-tab {
    padding: 20px 10px;
    flex-grow: 1;
    text-align: center;
    margin-right: 0; }
    .ant-tabs--styled .ant-tabs-nav .ant-tabs-tab:hover {
      color: #fff; }
  .ant-tabs--styled .ant-tabs-nav .ant-tabs-tab-active {
    color: #fff;
    font-weight: 700; }
  .ant-tabs--styled .ant-tabs-ink-bar {
    background-color: #fff;
    height: 8px; }

.downloads-list {
  margin-top: 30px; }

.kill-allowance-widget-inner-div {
  font-size: 15px; }

@media only screen and (max-width: 570px) {
  .kill-allowance-widget-inner-div {
    font-size: 2.5vw; } }

.kill-allowance-widget-header {
  font-weight: bold; }

.ant-notification.ant-notification-bottomLeft {
  left: 50% !important;
  -webkit-transform: translate(-50%);
  transform: translate(-50%); }

.ant-progress-circle.allowanceProgress {
  margin-bottom: 30px; }
  .ant-progress-circle.allowanceProgress .ant-progress-text .remainingTitle {
    font-size: 12px;
    margin-bottom: 0px;
    margin-bottom: 10px; }
  .ant-progress-circle.allowanceProgress .ant-progress-text .remainingNumber {
    margin-bottom: 0px;
    font-weight: bold;
    margin-bottom: 10px; }
  .ant-progress-circle.allowanceProgress .ant-progress-text .remainingBottomPercentage {
    margin-bottom: 0px;
    font-size: 12px; }
  .ant-progress-circle.allowanceProgress .ant-progress-text .remainingBottomText {
    margin-bottom: 0px;
    font-size: 12px; }

.linksHeader {
  max-height: 20vh;
  border-bottom-style: solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: #000000; }

.linksSectionContainer .linksSectionTitle {
  font-weight: bold; }

.linksSectionContainer .linksProductionName {
  font-size: 20px; }

.linksSectionContainer .linksSectionContentNote {
  border-style: solid;
  padding: 7px; }

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center; }

.linksUploader {
  font-weight: bold;
  margin-left: 24px;
  color: #FFFFFF;
  margin-top: 10px; }

.linksColContainer {
  padding: 48px;
  height: 90vh; }
  .linksColContainer .ant-tabs-content {
    border: 1px solid #e7e7e7;
    border-top: none;
    height: 30vh;
    overflow-y: scroll; }
  .linksColContainer .ant-tabs-bar {
    margin-bottom: 0; }
  .linksColContainer .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px; }
  .linksColContainer .dzu-dropzone {
    min-height: 360px;
    min-width: 200px;
    width: 90%;
    margin: 24px;
    border: 1px dashed #e7e7e7;
    overflow-y: hidden;
    background: linear-gradient(to right, #000 33%, #fff 0%) top/20px 1px repeat-x, linear-gradient(#000 33%, #fff 0%) right/1px 20px repeat-y, linear-gradient(to right, #000 33%, #fff 0%) bottom/20px 1px repeat-x, linear-gradient(#000 33%, #fff 0%) left/1px 20px repeat-y;
    /* left */ }

.uploader-preview_row {
  display: block;
  width: 100%;
  color: #000000;
  margin-bottom: 10px; }
  .uploader-preview_row .uploader-preview_row__icon {
    display: inline-block;
    width: 5%; }
  .uploader-preview_row .uploader-preview_row__name {
    display: inline-block;
    width: 70%; }
  .uploader-preview_row .uploader-preview_row__status {
    display: inline-block;
    width: 25%;
    font-size: 13px; }
    .uploader-preview_row .uploader-preview_row__status.small-status {
      font-size: 10px; }

.upload-stats {
  text-align: center; }
  .upload-stats .title-main {
    font-weight: bold; }

.linksFooterContainer {
  margin-top: 36px; }

.linksFooterButton {
  width: 100%;
  margin-top: 12px; }

.linkTabs {
  width: 90%;
  margin: 0 auto;
  max-height: 500px;
  margin-top: 24px;
  overflow-y: scroll;
  min-height: 250px; }

.linksButton-active {
  font-size: 0.45rem;
  width: 30%;
  background-color: red; }

.linksButton-inactive {
  font-size: 0.45rem;
  width: 30%; }

.linksContent {
  border-style: solid;
  border-color: lightgray; }

.footerContainer {
  position: absolute;
  bottom: 0;
  margin-bottom: 30px; }
