.dayimage-wrap {
  padding-top: 24px; }

.dayimage {
  position: relative;
  display: flex;
  margin-top: -1px;
  overflow: hidden; }
  .dayimage.killed .dayimage__action-icon {
    background-color: #c00000; }
  .dayimage.disabled {
    background-color: black;
    z-index: 10;
    opacity: 0.8; }
  .dayimage > a {
    width: 100%;
    z-index: 15; }
  .dayimage img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 5;
    position: relative; }
  .dayimage .spinnerBox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: 50%; }

.dayimage__action-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: rgba(74, 74, 74, 0.7);
  border: none; }
  .dayimage__action-icon > span {
    display: flex;
    justify-content: center;
    align-items: center; }
  .dayimage__action-icon .icon {
    fill: #fff;
    font-size: 15px; }
  .dayimage__action-icon .icon-eye-not {
    font-size: 22px;
    width: 26px;
    height: 22px;
    opacity: 0.7; }

.dayimage__title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  text-align: center;
  color: #000000; }

.day-image-link {
  min-height: 151px; }

.day-image-pic {
  min-height: 151px; }

.images-list-grid > :first-child {
  margin: 0 auto;
  position: relative; }

.image-single-action-row-small {
  z-index: 99; }

.dayimageWrapper {
  padding-left: 0.5px;
  padding-right: 0.5px;
  padding-bottom: 1px;
  margin-bottom: 1px;
  overflow: hidden; }

@media only screen and (max-width: 500px) {
  .day-image-link {
    min-height: 100px; }
  .day-image-pic {
    min-height: 100px; } }
