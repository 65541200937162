.footer {
  margin-top: 40px;
  color: #4a4a4a; }
  .footer p {
    margin-bottom: 0; }

.footer__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 19px;
  border-top: 1px solid #f5f1fa; }

.footer__copy {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-size: 12px; }
  .footer__copy a {
    font-size: inherit; }
