.ant-btn.custom-button {
  height: 30px;
  width: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important; }

.activeDaySelectorBlock {
  margin-top: 20px; }
  .activeDaySelectorBlock .middle-col {
    text-align: center; }
  .activeDaySelectorBlock .right-col {
    text-align: right; }
