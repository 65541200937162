.menu__wrap {
  position: fixed;
  top: 0;
  right: 100vw;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  color: #000;
  font-weight: normal;
  transition: right .3s ease-in-out; }
  .menu__wrap .menu__backdrop {
    display: inline-block;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    background: rgba(0, 0, 0, 0.3); }
  .menu__wrap.true {
    right: 0; }
    .menu__wrap.true .menu__backdrop {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1; }
    .menu__wrap.true .menu__inner {
      left: 0; }
  .menu__wrap p {
    margin-bottom: 0; }

.menu__inner {
  width: 100%;
  max-width: 375px;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  position: relative;
  text-align: center;
  padding: 30px 0 20px;
  background-color: #fff;
  transition: left .3s ease; }
  .menu__inner .menu__user {
    margin: 10px 0 30px; }

.menu__close {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1001; }

.menu__change {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .menu__change a {
    display: flex;
    align-items: center; }
  .menu__change .icon {
    font-size: 21px;
    margin-right: 16px;
    fill: currentColor; }

.menu__label {
  font-size: 12px;
  text-align: left; }

.menu__logout {
  display: inline-block;
  margin-top: 20px; }

.menu__nav {
  margin: 48px 0; }

.menu__navlist {
  padding: 0; }
  .menu__navlist li {
    padding: 25px 0;
    list-style-type: none;
    border-top: 1px solid #f5f1fa; }
    .menu__navlist li:last-child {
      border-bottom: 1px solid #f5f1fa; }

.menu__navlink {
  display: flex;
  align-items: center;
  padding-left: 25%;
  color: #000; }
  .menu__navlink:hover {
    color: #c00000; }
  .menu__navlink .icon {
    font-size: 18px;
    fill: #c00000; }

.menu__nav-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 9px; }

.menu__navlink-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  text-transform: uppercase;
  font-weight: 500; }

.menu__nav-data {
  margin-left: 8px;
  color: #4a4a4a; }

.even-height {
  width: 1em !important;
  height: 1em !important;
  font-size: 26px !important; }
