.imagesingle {
  margin-top: 24px; }

.imagesingle__figure {
  position: relative;
  width: 100%;
  min-height: 220px; }
  .imagesingle__figure .imagesingle__previous_btn {
    width: 10%;
    height: 100%;
    display: none;
    position: absolute;
    left: 0;
    top: 0; }
    .imagesingle__figure .imagesingle__previous_btn .icon {
      position: relative;
      width: 100%;
      height: 85%;
      margin: 50% auto;
      font-size: 18px;
      padding: 10px; }
  .imagesingle__figure .imagesingle__next_btn {
    width: 10%;
    height: 100%;
    display: none;
    position: absolute;
    right: 0;
    top: 0; }
    .imagesingle__figure .imagesingle__next_btn .icon {
      position: relative;
      width: 100%;
      height: 85%;
      margin: 50% auto;
      font-size: 18px;
      padding: 10px; }
  .imagesingle__figure:hover .imagesingle__previous_btn, .imagesingle__figure:hover .imagesingle__next_btn {
    display: block;
    background: rgba(0, 0, 0, 0.3); }
  .imagesingle__figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.image-single-action-row {
  position: absolute;
  width: 100%;
  height: 48px;
  bottom: 0;
  text-align: right; }
  .image-single-action-row .image-single-action-icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    line-height: 22px;
    text-align: center; }
    .image-single-action-row .image-single-action-icon.black {
      background-color: #000000; }
    .image-single-action-row .image-single-action-icon.red {
      background-color: #c00000; }
    .image-single-action-row .image-single-action-icon.orange {
      background-color: #ffb424; }
    .image-single-action-row .image-single-action-icon.green {
      background-color: #52c41a; }
    .image-single-action-row .image-single-action-icon .anticon {
      color: #fff;
      font-size: 24px; }
    .image-single-action-row .image-single-action-icon .image-single-action-label {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 60px;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.38;
      color: #ffffff; }

.image-single-action-row-small {
  position: absolute;
  width: 100%;
  height: 32px;
  bottom: 0;
  text-align: right; }
  .image-single-action-row-small .image-single-action-icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center; }
    .image-single-action-row-small .image-single-action-icon.black {
      background-color: #000000; }
    .image-single-action-row-small .image-single-action-icon.grey {
      background-color: #636468; }
    .image-single-action-row-small .image-single-action-icon.red {
      background-color: #c00000; }
    .image-single-action-row-small .image-single-action-icon.orange {
      background-color: #ffb424; }
    .image-single-action-row-small .image-single-action-icon.green {
      background-color: #52c41a; }
    .image-single-action-row-small .image-single-action-icon .anticon {
      color: #fff;
      font-size: 20px; }
    .image-single-action-row-small .image-single-action-icon .icon {
      fill: #fff;
      font-size: 16px;
      padding: 10px; }
    .image-single-action-row-small .image-single-action-icon .image-single-action-label {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 60px;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.38;
      color: #ffffff; }

.imagesingle__action-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #c00000; }
  .imagesingle__action-icon .icon {
    fill: #fff;
    font-size: 22px; }
  .imagesingle__action-icon .imagesingle__action-label {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 60px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #ffffff; }

.imagesingle__info {
  display: flex;
  align-items: center;
  margin-top: 19px;
  font-size: 12px;
  line-height: 1.83;
  color: #4a4a4a; }
  .imagesingle__info .icon-edit {
    font-size: 32px; }
  .imagesingle__info .icon {
    fill: #b9b9b9; }

.imagesingle__text-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.imagesingle__text {
  margin-left: 14px; }

.imagesingle__killer {
  display: flex;
  align-items: center;
  margin: 0 4px 0 8px;
  font-weight: 600;
  color: #000000; }
  .imagesingle__killer span {
    margin-left: 6px; }
  .imagesingle__killer .icon-eye {
    font-size: 13px; }
  .imagesingle__killer .icon-eye-not {
    font-size: 17px; }
  .imagesingle__killer .icon-deleted {
    font-size: 20px; }

.imagesingle__actions {
  min-height: 90px;
  margin-top: 34px;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: #4a4a4a; }
  .imagesingle__actions,
  .imagesingle__actions div {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .imagesingle__actions button {
    margin-bottom: 11px; }
    .imagesingle__actions button .icon-unkill {
      font-size: 28px; }
  .imagesingle__actions p {
    margin-bottom: 0; }
