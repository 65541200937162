.loginform-wrap {
  min-height: 100vh;
  padding: 30px 0;
  background: url("../../assets/images/sky-bg-mobile.jpg") no-repeat center bottom/cover;
  font-size: 14px;
  color: #fff;
  text-align: center; }
  .loginform-wrap .ant-form-vertical .ant-form-item {
    margin-bottom: 4px; }
  @media only screen and (min-width: 580px) {
    .loginform-wrap {
      background: url("../../assets/images/sky-bg.jpg") no-repeat center bottom/cover; }
      .loginform-wrap .ant-form-vertical .ant-form-item {
        margin-bottom: 20px; } }
  .loginform-wrap .ant-form-item-label label {
    color: #fff; }
  .loginform-wrap .ant-input {
    border-radius: 0;
    height: 40px; }
  .loginform-wrap .has-error .ant-form-explain,
  .loginform-wrap .has-error .ant-form-split {
    text-align: left;
    color: #c00000; }
  .loginform-wrap p {
    margin-bottom: 0; }

.loginform-container {
  max-width: 303px;
  margin: 0 auto; }
  @media only screen and (min-width: 580px) {
    .loginform-container {
      max-width: 580px;
      width: 80vw; } }

.loginform-backdrop {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 18px 0;
  padding: 21px 32px 16px 32px;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3), black); }
  .loginform-backdrop > * {
    z-index: 1; }
  .loginform-backdrop:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3); }
  @media only screen and (min-width: 580px) {
    .loginform-backdrop {
      margin: 5% 0;
      padding: 8% 10%; } }

.loginform__agree {
  color: #fff;
  margin: 15px 0 21px; }
  .loginform__agree p {
    font-size: 12px; }
  @media only screen and (min-width: 580px) {
    .loginform__agree {
      display: flex;
      align-items: center;
      justify-content: center; }
      .loginform__agree p {
        font-size: 14px;
        margin-right: 10px; } }

.loginform__forgotten {
  display: block;
  margin-top: 5px; }
