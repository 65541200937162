.dayimage-wrap {
  padding-top: 24px; }

.dayimage {
  position: relative;
  display: flex;
  margin-bottom: 1px;
  min-height: 99%; }
  .dayimage.inactive {
    opacity: 0.7; }
  .dayimage.killed .dayimage__action-icon {
    background-color: #c00000; }
  .dayimage.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5); }
  .dayimage > a {
    width: 100%; }
  .dayimage img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.dayimage__action-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: rgba(74, 74, 74, 0.7);
  border: none; }
  .dayimage__action-icon > span {
    display: flex;
    justify-content: center;
    align-items: center; }
  .dayimage__action-icon .icon {
    fill: #fff;
    font-size: 15px; }
  .dayimage__action-icon .icon-eye-not {
    font-size: 22px;
    width: 26px;
    height: 22px;
    opacity: 0.7; }

.dayimage__title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  text-align: center;
  color: #000000; }

.dayimage-spinner-container {
  width: 100%;
  height: inherit;
  background: rgba(0, 0, 0, 0.5); }
