.navbuttons {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #f0f0f0; }
  .navbuttons button, .navbuttons a {
    width: 100%;
    height: 72px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    border: 1px solid #d7d7d7;
    transition: color 0.3s ease; }
    .navbuttons button:hover, .navbuttons a:hover {
      color: #c00000; }
    .navbuttons button .icon, .navbuttons a .icon {
      margin: 0 16px;
      fill: currentColor;
      font-size: 17px; }
  .navbuttons button.disabled {
    color: #CCC;
    cursor: default; }
    .navbuttons button.disabled:hover {
      color: #CCC; }
