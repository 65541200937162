.select-days {
  margin-top: 24px; }

.select-day {
  margin-bottom: 16.5px;
  padding: 15px 17px 14px 20px;
  box-shadow: 0 4px 10px 4px #f5f1fa;
  background-color: #ffffff; }
  .select-day.checked .select-day__name {
    display: flex;
    align-items: center;
    color: #25b107; }
    .select-day.checked .select-day__name .icon {
      margin-right: 7px;
      font-size: 21px;
      fill: currentColor;
      stroke: #25b107;
      stroke-width: 1px;
      display: inline-block; }
  .select-day .icon-arrownext {
    fill: #c00000;
    font-size: 35px; }
  .select-day p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: #4a4a4a; }
  .select-day .select-day__footer p:first-child {
    margin-top: 16px; }

.select-day__name {
  font-weight: 700;
  font-size: 16px;
  color: #000; }
  .select-day__name .icon {
    display: none; }
